import React from "react"
import { Link } from 'gatsby';

const Footer = () => (
    <footer className="footer">
        <a href="mailto:team@threadheap.com" className="link link--light">Contact us</a>
        {/* <a href="https://pavel207.typeform.com/to/jIICkA" target="blank" className="link link--light">Newsletter</a> */}
        <Link to="/blog" className="link link--light">Blog</Link>
        <Link to="/privacy" className="link link--light">Privacy policy</Link>
        <Link to="/terms" className="link link--light">Terms of use</Link>
        <br />
        © 2024 ThreadHeap
    </footer>
)

export default Footer
